import React, { useState } from 'react'
import './list.scss'
import CustomMarkdown from '../../utils/customMarkdown'
//import 'uikit/dist/css/uikit.css'


const List = ({ contents }) => {

    // const [title, setTitle] = useState(null);
    // const [prevTitle, setPrevTitle] = useState(null);


    // const updateTitle = (newTitle) => {
    //     if (newTitle !== "EMPTY" && newTitle !== prevTitle) {
    //         setTitle(newTitle);
    //         setPrevTitle(newTitle);
    //         return newTitle;
    //     }
    //     return null;
    // };


    return (
        <div style={{ minHeight: "500px" }}>
            <div className="list-container uk-animation-fade">
                {
                    contents?.map((item, index) => {

                        return (
                            <div className="padding-top-bottom">
                                {
                                    item.GroupTitle == "EMPTY" ? <b style={{ display: "block", width: "1px", marginBottom: "20px" }} /> : item.GroupTitle !== null ? <h2 className="group-title">{item.GroupTitle}</h2> : ""
                                }


                                {
                                    item.Products ?
                                        <div>
                                            <div className="list-content">
                                                <p className="product-title">{item.Products.Title}
                                                    {/* {item.Products.Vegan ? <b> (VG)</b> : ""}
                                                    {item.Products.Vegetarian ? <b> (V)</b> : ""}
                                                    {item.Products.Nuts ? <b> (N)</b> : ""}
                                                    {item.Products.Spicy ? <b> (S)</b> : ""} */}
                                                </p>
                                                <p className="product-price">{item.Products.Price}</p>
                                            </div>
                                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                <CustomMarkdown className="product-description">{item.Products.Description}</CustomMarkdown>
                                                {item.Products.Vegan ? <b> (VG)</b> : ""}
                                                {item.Products.Vegetarian ? <b> (V)</b> : ""}
                                                {item.Products.Nuts ? <b> (N)</b> : ""}
                                                {item.Products.Spicy ? <b> (S)</b> : ""}
                                            </div>
                                        </div>

                                        : ""
                                }

                            </div>


                        )

                    })
                }
            </div>

        </div>
    )
}

export default List